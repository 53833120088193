@import url('https://fonts.googleapis.com/css?family=Hind:300,400&display=swap');

.container-faq {
    color: #5648FC;


}

.faq-heading {
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: normal;
    text-align: center;
}

.accordion-item-faq {
    margin: 20px;
    padding: 0 25px 0 25px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    background: linear-gradient(0.25turn, #5748fc80, #407cff9f);

    @media (max-width: 786px) {
        margin: 10px;
    }
}

.accordion-faq button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: #5748fcc4;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
}

.accordion-faq button:hover,
.accordion-faq button:focus {
    cursor: pointer;
    color: white;
}

.accordion-faq button .accordion-title-faq {
    padding: 1em 1em 1em 0;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: bold;
}

.accordion-faq button .icon-faq {
    display: inline-block;
    position: absolute;
    width: 22px;
    right: 0;
    height: 22px;
    border: 1px solid;
    border-radius: 22px;

}

.accordion-faq button .icon-faq::before {
    display: block;
    position: absolute;
    content: '';
    top: 10px;
    left: 6px;
    width: 10px;
    height: 2px;
    background: currentColor;
}

.accordion-faq button .icon-faq::after {
    display: block;
    position: absolute;
    content: '';
    top: 6px;
    left: 10px;
    width: 2px;
    height: 10px;
    background: currentColor;
}

.accordion-faq button[aria-expanded='true'] {
    padding-bottom: 3px;
    color: #ffffff;
}

.accordion-faq button[aria-expanded='true'] .icon-faq::after {
    width: 0;
}

.accordion-content-faq {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    color: black;
    transition: opacity 200ms linear, max-height 200ms linear;
}

.accordion-content-faq p {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 1em;
}

.accordion-content-faq[style*='opacity: 1'] {
    opacity: 1;
    max-height: 9em;

}

.accordion-title-faq {
    color: #5648FC;
}



.swiper-slide {
    width: 37rem;
    height: 300px;    
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        width: 37rem;
        height: 47rem;
    }

    .swiper-slide {
        width: 28rem !important;
        height: 36rem !important;
    }

    .swiper-slide img {
        width: 28rem !important;
        height: 36rem !important;
    }
}

.swiper-slide img {
    width: 37rem;
    height: 42rem;
    border-radius: 2rem;
    object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}